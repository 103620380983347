import { DOT_SYMBOL, EMPTY_VALUE_PLACEHOLDER } from '@/constants'

/**
 * Truncate the middle of the value to only keep the begining and the end.
 * Truncated content is replace by ..
 */
export function truncateMiddle(value?: string, numberOfLeadingChars: number = 4, numberOfTrailingChars: number = 4) {
    if (!value) {
        return EMPTY_VALUE_PLACEHOLDER
    }

    if (value.length < numberOfLeadingChars + numberOfTrailingChars) {
        return value
    }

    const firstChars = value?.slice(0, numberOfLeadingChars)
    const lastChars = value?.slice(-numberOfTrailingChars)

    return `${firstChars}${DOT_SYMBOL}${DOT_SYMBOL}${lastChars}`
}
