import { TableCellAccount } from '@/components'
import { BULLET_SEPARATOR, EMPTY_VALUE_PLACEHOLDER } from '@/constants'
import { useNavigateWithLegalEntityID, useNavigationRoutes, useQueryFindByIdExternalAccount } from '@/hooks'
import { truncateMiddle } from '@/utils'
import { Link, Skeleton } from '@chakra-ui/react'
import { Account, Uuid } from '@webapps/numeral-ui-core'
import { memo, useCallback } from 'react'

type PaymentOrderDetailsToProps = {
    receivingAccount?: Account
    receivingAccountID?: Uuid
}

export const PaymentOrderDetailsTo: React.FC<PaymentOrderDetailsToProps> = memo(
    ({ receivingAccount, receivingAccountID }) => {
        const { navigateWithLegalEntityID } = useNavigateWithLegalEntityID()
        const { paths, relativePaths } = useNavigationRoutes()

        const queryExternalAccount = useQueryFindByIdExternalAccount(receivingAccountID, {
            enabled: globalThis.Boolean(receivingAccountID)
        })

        const formattedExternalAccount = `${queryExternalAccount.data?.holder_name} ${BULLET_SEPARATOR} ${queryExternalAccount.data?.name} ${BULLET_SEPARATOR} ${truncateMiddle(queryExternalAccount.data?.account_number)}`

        const onClick = useCallback(() => {
            const path = `${paths.COUNTERPARTIES.EXTERNAL_ACCOUNTS}/${receivingAccountID}/${relativePaths.COUNTERPARTIES.DETAILS}`
            navigateWithLegalEntityID(path)
        }, [paths, relativePaths, navigateWithLegalEntityID, receivingAccountID])

        switch (true) {
            case queryExternalAccount.isLoading:
                return <Skeleton height="14px" width="50%" />
            case queryExternalAccount.isSuccess:
                return <Link onClick={onClick}> {formattedExternalAccount} </Link>
            case globalThis.Boolean(receivingAccount):
                return <TableCellAccount value={receivingAccount} />
            default:
                return <> {EMPTY_VALUE_PLACEHOLDER}</>
        }

        return null
    }
)
